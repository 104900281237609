<script setup>
import logo from '@/assets/logo/logo.png'
import PlatformList from './components/platformList.vue'
import CopyrightInfo from '@/components/copyrightInfo.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import Connect from './components/connect.vue'
let platformListShow = ref(false)
let connectStore = ref('')

const route = useRoute()

if (!!route.query.platform) {
  platformListShow.value = true
  connectStore.value = route.query.platform
}

let connect = (val) => {
  connectStore.value = val
  platformListShow.value = true
}

let back = () => {
  connectStore.value = ''
  platformListShow.value = false
}
</script>

<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 70px);
    "
  >
    <div class="main">
      <div class="w1120">
        <div class="title_logo">
          <div class="img">
            <el-image :src="logo"></el-image>
          </div>
          <div class="title">Let's connect your store!</div>
        </div>
        <PlatformList v-if="!platformListShow" @connect="connect" />
        <Connect v-else :connectStore="connectStore" @back="back" />
      </div>
    </div>
    <div>
      <CopyrightInfo mode="white" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/views/myAccount/account.scss';
.main {
  display: flex;
  justify-content: center;
  .w1120 {
    width: 1120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_logo {
      margin: 80px 0;
      .title {
        margin-top: 30px;
        font-size: 26px;
        font-weight: bold;
        line-height: 37px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
      }
      .img {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>

<script setup>
import { defineComponent, reactive, toRefs } from 'vue'
import shopify from '@/assets/connectStore/shopify.png'
import woo from '@/assets/connectStore/woo.png'
import wix from '@/assets/connectStore/wix.png'
import shopline from '@/assets/connectStore/shopline.png'

let platform = reactive({
  list: [
    {
      id: 1,
      title: 'Shopify',
      Platform: 'Platform',
      describe: 'Ecommerce platform with everything you need to sell online.',
      status: 1,
      img: shopify,
    },
    // {
    //   id: 2,
    //   title: "Woocommerce",
    //   Platform: "Open source platform",
    //   describe:
    //     "The simplest way to monetize blogs and websites built on WordPress.",
    //   status: 0,
    //   img: woo,
    // },
    {
      id: 2,
      title: 'Shopline',
      Platform: 'Platform',
      describe:
        'No design experience or coding required. Build an online store with ease.',
      status: 1,
      img: shopline,
    },
    {
      id: 3,
      title: 'Wix',
      Platform: 'Platform',
      describe:
        'A platform with an intuitive drag and drop builder and 500+ customizable designer-made templates.',
      status: 0,
      img: wix,
    },
  ],
})

let emit = defineEmits(['connect'])
let connect = (val) => {
  emit('connect', val)
}
</script>

<template>
  <div class="flex box">
    <div class="platform_card" v-for="item in platform.list" :key="item.id">
      <div class="flex">
        <div class="img">
          <el-image :src="item.img"></el-image>
        </div>
        <div class="platform_card_text">
          <div class="title">{{ item.title }}</div>
          <div class="Platform">{{ item.Platform }}</div>
        </div>
      </div>
      <div class="describe">
        <div>
          {{ item.describe }}
        </div>
        <el-button
          :type="item.status ? 'primary' : 'info'"
          :disabled="!item.status"
          @click="connect(item.title)"
          >{{ item.status ? 'Connect' : 'Not yet open' }}</el-button
        >
      </div>
    </div>
  </div>
  <div class="back_box">
    <el-button class="Back" @click="$router.back()">Back</el-button>
  </div>
</template>

<style scoped lang="scss">
.box {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 175px;
}
.platform_card {
  width: 340px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 3px;
  padding: 20px;
  .img {
    width: 80px;
    height: 80px;
    // background-color: red;
    margin-right: 20px;
  }
  .platform_card_text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
    .Platform {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .describe {
    min-height: 115px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.back_box {
  display: flex;
  justify-content: center;
  .Back {
    width: 200px;
    font-size: 16px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1400px) {
  .box {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    .platform_card {
      margin-bottom: 20px;
    }
  }
  .Back {
    margin-bottom: 50px;
  }
}
</style>

<script setup>
import { ref } from 'vue'
import shopify from '@/assets/connectStore/shopify2.png'
import shopline from '@/assets/connectStore/shopline2.png'
import { getInitiativeToBindShopifyUrl } from '@/api/connectStore'
import { ElMessage } from 'element-plus'

const props = defineProps({
  connectStore: {
    type: String,
    default: () => '',
  },
})

const emit = defineEmits(['back'])

let url = ref('')

let back = () => {
  emit('back')
}

let loading = ref(false)

let errorShow = ref(false)
let connect1 = async () => {
  // 1.判断是否有.myshopify.com
  let reg = /\.myshopify\.com/

  let url2 = reg.test(url.value)

  if (!!url2) {
    errorShow.value = false
    //2.解析域名传给后台
    let urlReg =
      /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
    let a = url.value.match(urlReg)
    if (!!url.value) {
      loading.value = true
      try {
        const { data } = await getInitiativeToBindShopifyUrl({
          shop: a[0],
        })
        window.open(data?.url)
      } catch (error) {
        ElMessage.error(error)
      }
      loading.value = false
    }
  } else {
    errorShow.value = true
  }

  //
  // let url2 = url.value.replace(urlReg,1);
  // alert(url2);
}

let connect = async () => {
  console.log()
  url.value = url.value.toLocaleLowerCase()
  let name = '.my' + props.connectStore.toLocaleLowerCase() + '.com'
  // let reg = /\.myshopify\.com/
  let reg = new RegExp(name, 'g')
  let url2 = reg.test(url.value)

  //不存在匹配规则
  if (!url2) {
    errorShow.value = true
    return
  }
  errorShow.value = false
  loading.value = true
  let regHttps = /(https):\/\/([\w.]+\/?)\S*/gi
  //必须添加https
  const httpsStatus = regHttps.test(url.value)
  try {
    const { data } = await getInitiativeToBindShopifyUrl({
      shopUrl: !httpsStatus ? 'https://' + url.value : url.value,
      partnerType: props.connectStore.toLocaleLowerCase(),
    })
    window.open(data?.authUrl)
  } catch (error) {
    ElMessage.error(error)
  }
  loading.value = false
}

let addByEnterKey = (e) => {
  if (e.keyCode === 13) {
    connect()
  }
}
</script>

<template>
  <div class="card">
    <div class="img">
      <el-image
        :src="shopline"
        alt="shopline"
        v-if="connectStore === 'Shopline'"
      ></el-image>
      <el-image :src="shopify" alt="shopify" v-else></el-image>
    </div>

    <div class="connect">Connect {{ connectStore }}</div>
    <div class="add_store_url">
      Add your {{ connectStore }} store URL to connect with 99diy.
    </div>
    <div style="padding-bottom: 30px; width: 100%; position: relative">
      <el-input
        v-model="url"
        :placeholder="'e.g. my-shop.' + 'my' + connectStore + '.com'"
        @keydown="addByEnterKey"
      ></el-input>

      <transition name="el-zoom-in-top">
        <div class="error_text" v-show="errorShow">
          Please fill in a valid link.
        </div>
      </transition>
    </div>
    <div class="btn_box">
      <el-button type="info" @click="back">Back</el-button>
      <el-button type="primary" @click="connect" :loading="loading"
        >Connect</el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  max-width: 600px;
  width: 600px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    margin-top: 50px;
  }
  .connect {
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  .add_store_url {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .error_text {
    text-align: left;
    width: 100%;
    margin-top: 5px;
    color: red;
    position: absolute;
  }
  .btn_box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    // margin-top: 30px;
  }
}
</style>

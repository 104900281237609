import request from '@/utils/request.js';

/**
 * 获取完成的步骤
 */
export function getInitiativeToBindShopifyUrl(
    {
      shopUrl,
      partnerType
    }
) {
  return request({
    url: '/partner/activeAuth',
    method: 'POST',
    data: {
      shopUrl,
      partnerType
    }
  })
}